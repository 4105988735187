@import url("https://fonts.cdnfonts.com/css/mark-pro");
@import "./scss/menu.scss";
@import "./scss/button.scss";
@import "./scss/gallery.scss";
@import "./scss/accordion.scss";
* {
  padding: 0;
  margin: 0;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body,
html,
div,
blockquote,
img,
label,
p,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
ul,
ol,
li,
dl,
dt,
dd,
form,
a,
fieldset,
th,
td {
  border: 0;
  outline: none;
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}
html {
  font-family: "Mark Pro", sans-serif;
  font-size: 10px;
}
body {
  margin: 0;
  text-rendering: optimizeLegibility;
  background: #000000;
  color: #ffffff;
  position: relative;
  overflow-x: hidden;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.8;
  font-weight: 300;
  font-family: "Mark Pro", sans-serif;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin-bottom: 15px;
}
#page {
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  filter: grayscale(100%);
}
img:hover {
  filter: grayscale(0%);
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
::selection {
  background: #1e2023;
  color: white;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.nav {
  font-family: "Mark Pro", sans-serif;
}
.menu-item {
  line-height: 8rem;
}
.nav a {
  color: #ffffff;
}
.nav a:hover {
  color: #adadad;
}

.nav-right-title {
  display: flex;
  align-items: center;
  .menu-language {
    margin-right: 17px;
    button {
      border: none;
      background-color: #000000;
      color: white;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.sup {
  color: #e2e7e9;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 2.4rem;
  font-weight: 700;
  letter-spacing: 0.421rem;
  margin-bottom: 1em;
}
H1,
H2,
H3,
H4,
H5 {
  font-family: "Mark Pro", sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  margin: 0;
  margin-bottom: 24px;
  line-height: 1.42;
  color: #ffffff;
}
H1 {
  color: #fff;
  font-size: 46px;
}
H2 {
  font-size: 44px;
}
H3 {
  font-weight: 300;
  color: #fff;
  font-size: 32px;
}
H4 {
  font-weight: 700;
  color: #fff;
  font-size: 28px;
}
H5 {
  font-size: 22px;
  font-weight: 500;
}
.lead {
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 36px;
  color: #ccc;
  margin-bottom: 36px;
}

a {
  text-decoration: none;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  color: #747474;
}
a:active {
  color: #444;
}
a:visited {
}
a:hover {
  color: #1e2023;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
hr {
  color: #a6a9ac;
  border: none;
  background: #a6a9ac;
  height: 1px;
  border: none;
  margin: 0;
  display: block;
  width: 50%;
  margin: 0 auto;
  display: none;
}

td {
}
.container,
.responsive {
  width: 1250px;
  margin-right: auto;
  margin-left: auto;
}

.clearfix:after {
  display: table;
  content: "";
  clear: both;
}

.relative {
  position: relative;
}
.content {
}
.color1 {
  color: #1e2023;
}
.color-white {
  color: #ffffff;
}
.bg-white {
  background: #fff;
}
.bg1 {
  background: #1e2023;
}
.bg2 {
  background: #eaebe9;
}
.row {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.space-between {
  justify-content: space-between;
}

.odd_even .fluid_content:nth-child(odd) .row {
  display: flex;
  flex-direction: row-reverse;
}
.odd_even .fluid_content:nth-child(odd) .row .text_col {
  padding-left: 50px;
}
.odd_even .fluid_content:nth-child(even) .row .text_col {
  padding-right: 50px;
}

.video-yt {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 4:3 Aspect Ratio */
}

.video-bg {
  height: 100vh;
  position: relative;
  filter: grayscale(50%);
}
.video-bg .video-home-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.5) 51%,
    rgba(0, 0, 0, 0.7) 100%
  );
  z-index: 2;
  width: 100%;
  height: 100%;
}
.flexible {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -999;
  top: 0;
  left: 0;
}
.home-caption {
  position: absolute;
  bottom: 210px;
  left: 0;
  width: 100%;
  padding: 0px 0;
}
.home-caption h1 {
  position: relative;
  font-weight: 300;
  font-size: 9rem;
  line-height: 13rem;
}
.home-caption .sup {
  margin: 0;
}

.parallax {
  position: relative;
}
.parallax-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 60px 0;
}

.parallax-overlay {
  background-image: linear-gradient(
    rgba(3, 3, 3, 0.4) 0%,
    rgba(3, 3, 3, 0.5) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.parallax-content {
  background-image: linear-gradient(
    rgba(3, 3, 3, 0.9) 0%,
    rgba(3, 3, 3, 0.9) 100%
  );
}

#header {
  padding: 0px 0;
  margin: 0px;
  position: fixed;
  background-color: black;
  width: 100%;
  z-index: 2;
}
#header .header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#header .logo {
  display: block;
  width: 200px;
}
#header .logo svg {
  width: 100%;
  height: auto;
}

.main_h {
  position: fixed;
  z-index: 9;
  width: 100%;
  background: none;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 1;
  top: 0;
  left: 0;
}
.sticky {
  background-color: #000000;
  opacity: 1;
  top: 0px;
}

.nav-active header {
  background-color: #000000;
  opacity: 1;
  top: 0px;
}

.load_gif {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/************Footer************/
#footer {
  position: relative;
  margin: 0px 0 0px 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 0);
  background-image: linear-gradient(
      rgba(3, 3, 3, 0.88) 0%,
      rgba(3, 3, 3, 0.88) 100%
    ),
    url(assets/bg1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  border-width: 0px;
  border-color: rgb(226, 226, 226);
  border-style: solid;
  background-size: cover;
}
#footer .footer-content {
  padding: 90px 0;
}
#footer a {
  color: #747474;
}
#footer a:hover {
  color: #888888;
}
#footer p {
  margin: 0 0 20px 0;
  font-size: 17px;
}

.footer-row {
  display: flex;
  justify-content: space-between;
}
.footer-row .footer-right {
  width: 200px;
}

.footer-social {
  display: flex;
}
.footer-social li {
  margin-right: 10px;
}
.footer-social li a {
  font-size: 22px;
}

#copyright {
  background: #1e2023;
  padding: 10px 0;
  font-size: 12px;
}
#copyright a {
  color: #666666;
}
#copyright a:hover {
  color: #888888;
}
#copyright a.publifarm {
  display: inline-block;
  width: 90px;
}

div.table_content {
  display: table;
  width: 100%;
}
div.table_content > div {
  display: table-cell;
  vertical-align: top;
}
div.table_content > div.valign-top {
  vertical-align: top;
}
div.table_content > div.valign-middle {
  vertical-align: middle;
}
div.table_content > div.valign-bottom {
  vertical-align: bottom;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

.width25,
.col-3 {
  width: 25%;
}
.width33,
.col-4 {
  width: 33.33%;
}
.width50,
.col-6 {
  width: 50%;
}
.width66 {
  width: 66.66666%;
}
.width75,
.col-9 {
  width: 75%;
}

.col-1 {
  width: 8.3333%;
}
.col-11 {
  width: 91.6667%;
}

#header #nav {
  float: right;
}
#header #nav ul {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
#header #nav ul li {
  float: left;
  text-align: center;
  margin-left: 48px;
  position: relative;
}
#header #nav ul li:first-child {
  margin-left: 0;
}

#header #nav ul li a {
  color: #737678;
  line-height: 20px;
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0px;
  text-align: center;
}
#header #nav ul li.active a {
  color: #2b2e31;
}
#header #nav ul li a:hover {
  color: #1e2023;
}

/* Form */
.wpcf7 {
  padding-left: 0px;
}

.wpcf7 .form-row {
  margin-left: -5px;
  margin-right: -5px;
  position: relative;
}
.wpcf7 .form-row:after {
  display: table;
  clear: both;
  content: " ";
}
.wpcf7 .form-row > div {
  padding: 5px;
  float: left;
  width: 100%;
}
.wpcf7 .form-row > div.form-half {
  width: 50%;
}
.wpcf7 label {
  padding: 0;
  margin: 0;
}
.wpcf7 input {
  background: #eaebe9;
  color: #2b2e31;
  border: 1px solid #cccccc;
  margin: 5px 0;
  padding: 15px 18px;
  width: 100%;
  margin: 5px 0;
  background-color: #151515;
  font-size: 16px;
  color: #ffffff;
  border: 1px solid #151515;
  border-radius: 3px;
}
.wpcf7 textarea {
  background: #eaebe9;
  color: #2b2e31;
  border: 1px solid #cccccc;
  float: left;
  padding: 15px 18px;
  width: 100%;
  margin: 0 0px 0px 0;
  background-color: #151515;
  font-size: 16px;
  margin: 5px 0;
  color: #ffffff;
  border: 1px solid #151515;
  border-radius: 3px;
}
.wpcf7 input:focus,
.wpcf7 textarea:focus {
  border: 1px solid #746c65;
  color: #746c65;
  outline: 0;
}
.wpcf7 input[type="submit"] {
  float: right;
  background: #1e2023;
  color: #ffffff;
  padding: 15px 58px;
  margin: 5px 0;
  width: auto;
  cursor: pointer;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 3px;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.wpcf7 input[type="submit"]:hover {
  color: #ffffff;
  background: #2b2e31;
  border: 1px solid #333333;
}
::-webkit-input-placeholder {
  color: #aeaeae;
  font-family: "Barlow", sans-serif;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #aeaeae;
  font-family: "Barlow", sans-serif;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #aeaeae;
  font-family: "Barlow", sans-serif;
}

:-ms-input-placeholder {
  color: #aeaeae;
  font-family: "Barlow", sans-serif;
}
/* ----------- ------- ----------- */
.intro {
  max-width: 700px;
  margin: 0 auto;
}
.border-bottom {
  border-bottom: 1px solid #aeaeae;
}
.home-icon {
  font-size: 62px;
}
/*_________________________________________________________________________________________*/
.map {
  padding: 0;
  vertical-align: bottom;
}

.buttons {
  padding: 40px 0 0 0;
}

.accordion {
  margin: 8px 0;
  border: none;
}
.accordion:hover {
  border: 1px solid #ffffff;
}
.accordion:hover .accordion-top {
  color: #ffffff;
}
.accordion-top {
  padding: 10px 25px;
  display: flex;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}
.accordion-bottom {
  display: none;
  padding: 15px 25px;
  border-top: 1px solid rgba(181, 181, 181, 0.27);
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.mt {
  margin-top: 120px;
}
.mb {
  margin-bottom: 120px;
}
.pt {
  padding-top: 120px;
}
.pb {
  padding-bottom: 120px;
}
.pr {
  padding-right: 90px;
}
.pl {
  padding-left: 90px;
}
.pt2 {
  padding-top: 42px;
}
.pb2 {
  padding-bottom: 42px;
}
.pr2 {
  padding-right: 42px;
}
.pl2 {
  padding-left: 42px;
}
.mt2 {
  margin-top: 42px;
}
.mb2 {
  margin-bottom: 42px;
}
.mb0 {
  margin-bottom: 0px !important;
}

.pt-icon {
  padding-top: 150px;
}
.pb-icon {
  padding-bottom: 150px;
}

section.sede {
  display: flex;
  min-height: 100vh;
}

.dir {
  position: fixed;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -200%);
}

.txtBlock {
  flex-basis: 33%;
  padding: 60px;
}

.thumbs {
  flex-basis: 66%;
  font-size: 0;
  line-height: 0;
}

.thumb {
  display: inline-block;
  width: 46%;
  margin-top: 2%;
  margin-left: 2%;
}

.thumbImg {
  background: #fff;
  position: relative;
  display: block;
  width: 100%;
  height: 290px;
}

.stats {
  padding: 0 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  .udm {
    padding-left: 8px;
  }
}
.stats > div {
  text-align: center;
}
.stats .odometer {
  font-size: 76px;
  line-height: 90px;
  text-align: center;
  font-weight: 800;
}
.stats .odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.stats .odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  overflow: visible;
}

.stats h5 {
  color: #e2e7e9;
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 700;
  letter-spacing: 0.421rem;
  text-transform: uppercase;
}

.stats-sedi {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  > div {
    width: 33.33%;
    text-align: center;
    justify-content: center;
    padding: 20px;
    border-right: 1px solid rgba(181, 181, 181, 0.27);
    &:last-child {
      border-right: none;
    }
  }
  .odometer {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.1;
    margin: 5px;
  }
  h5 {
    margin: 0px 0 0 0;
  }
}

.parallax-banner {
  height: 90vh;
  filter: grayscale(100%);
  transition: all 4s ease;
}
.parallax-banner:hover {
  filter: grayscale(0%);
}
.parallax-quality {
  position: relative;
}
.parallax-quality ul {
  display: flex;
  color: #969696;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 50px;
}
.parallax-quality ul li {
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  padding: 0 0 40px 0;
}
.parallax-quality ul li.fusion-li-item {
  display: flex;
  border-bottom: none;
  padding: 0;
}
.parallax-quality ul li p {
  font-size: 18px;
}
.parallax-quality ul li a {
  color: #ffffff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  padding: 0 0 40px 0;
  display: flex;
}
.parallax-quality ul li.fusion-li-item a .material-icons {
  margin-right: 12px;
  color: #555555;
}
.parallax-quality ul li.fusion-li-item a:hover .material-icons {
  color: #ffffff;
}
.parallax-quality ul li a:hover {
  color: #ffffff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
}
.parallax-quality ul li h5 {
}

.silusi-cards {
  width: 640px;
  height: 420px;
}
.swiper-cards .swiper-slide {
  border-radius: 0.75rem;
}
.silusi-cards img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.testata {
  padding: 280px 0 120px;
  background-position: left top;
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(90%);
  -webkit-transition: all 4s ease;
  -moz-transition: all 4s ease;
  transition: all 4s ease;
  &:hover {
    filter: grayscale(0%);
  }
  h1 {
    font-size: 45px;
  }
  &__azienda {
    background-image: linear-gradient(
        180deg,
        rgba(0, 191, 50, 0.33) 0%,
        rgb(0 0 0) 100%
      ),
      url(../public/img/testata/testata8.jpg);
  }
  &__sedi,
  &__madeineurope-romania {
    background-image: linear-gradient(
        180deg,
        rgba(0, 191, 50, 0.33) 0%,
        rgb(0 0 0) 100%
      ),
      url(../public/img/testata/L1130942.jpg);
  }
  &__team {
    background-image: linear-gradient(
        180deg,
        rgba(0, 191, 50, 0.33) 0%,
        rgb(0 0 0) 100%
      ),
      url(../public/img/testata/testata3.jpg);
  }
  &__materiali {
    background-image: linear-gradient(
        180deg,
        rgba(0, 191, 50, 0.33) 0%,
        rgb(0 0 0) 100%
      ),
      url(../public/img/testata/testata-materiali.jpg);
  }
  &__lavorazioni {
    background-image: linear-gradient(
        180deg,
        rgba(0, 191, 50, 0.33) 0%,
        rgb(0 0 0) 100%
      ),
      url(../public/img/testata/testata4.jpg);
  }
  &__quality {
    background-image: linear-gradient(
        180deg,
        rgba(0, 191, 50, 0.33) 0%,
        rgb(0 0 0) 100%
      ),
      url(../public/img/testata/testata5.jpg);
  }
  &__mercati {
    background-image: linear-gradient(
        180deg,
        rgba(0, 191, 50, 0.33) 0%,
        rgb(0 0 0) 100%
      ),
      url(../public/img/testata/testata6.jpg);
  }
  &__contatti {
    background-image: linear-gradient(
        180deg,
        rgba(0, 191, 50, 0.33) 0%,
        rgb(0 0 0) 100%
      ),
      url(../public/img/testata/testata-materiali.jpg);
  }
  &__lanostrastoria {
    background-image: linear-gradient(
        180deg,
        rgba(0, 191, 50, 0.33) 0%,
        rgb(0 0 0) 100%
      ),
      url(../public/img/testata/testata3.jpg);
  }
}

.video-container {
  position: relative;
}
.load_video {
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #1c273a;
}

.lista-sedi {
  margin: 20px 0;
  align-items: center;
  justify-content: center;
}
.lista-sedi .col-3 {
  border-right: 1px solid rgba(181, 181, 181, 0.27);
}

.section-mercati {
  padding: 100px 0;
}
.section-mercati .row {
  align-items: center;
  margin-left: -20px;
  margin-right: -20px;
}
.section-mercati .row > div {
  padding: 0 20px;
}
.section-mercati .section-txt {
  width: 40%;
}
.section-mercati .section-img {
  width: 60%;
  position: relative;
}
.section-mercati .img-squared {
  position: relative;
}
.section-mercati .img-squared:before {
  content: " ";
  display: block;
  width: 100%;
  padding-top: 100%;
  position: relative;
}
.section-mercati .img-squared img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.section-mercati {
  padding: 100px 0;
}
.section-mercati:nth-child(even) .row {
  flex-direction: row-reverse;
}

.contatti-wrapper {
  display: flex;
}
.contatti-wrapper .contatti_romania {
  width: 75%;
}
.contatti-wrapper .contatti_italia {
  width: 25%;
}
.contatti-wrapper {
  font-size: 14px;
  line-height: 1.45;
}
.contatti-wrapper ul {
  list-style: none;
  display: flex;
  width: 100%;
  height: 100%;
}
.contatti-wrapper ul li {
  background: rgb(27 27 27 / 19%);
  margin: 12px 0;
  padding: 0px 40px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.contatti-wrapper ul li p {
  margin-bottom: 0;
}
.contatti-wrapper ul.indirizzi-romania {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.modulo-wrapper {
  max-width: 500px;
  margin: 0 auto;
}

.video-section:nth-child(even) .row {
  flex-direction: row-reverse;
}
.container100 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -30px;
  margin-right: -30px;
}
.section-wrapper {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 100px 0;
}
.video-section {
  width: 100%;
  padding: 0;
  margin: 0 auto;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}
.section-wrapper:nth-child(odd) .video-section {
  justify-content: flex-end;
  flex-direction: row-reverse;
}
.video-section .video-img {
  width: 70%;
  padding: 0 20px;
}
.video-section .video-img video {
  width: 100%;
  height: auto;
}
.video-section .video-txt {
  flex: 1;
}
.section-wrapper:nth-child(odd) .video-section .video-txt {
  text-align: left;
}
.video-section h2 {
  font-weight: 800;
  margin: 0px 0;
  font-size: 70px;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 40px;
  transform: translateY(-50%);
  left: 40px;
  z-index: 2;
}
.section-wrapper:nth-child(odd) .video-section h2 {
  left: auto;
  right: 40px;
}
.video-txt-wrapper {
  padding-right: 30px;
}

.sedi-europe ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  li {
    padding-top: 10px;
    text-align: center;
    font-weight: 800;
    letter-spacing: 2px;
  }
}

.intro-bg {
  padding: 280px 0 120px;
}
.video-container video {
  width: 100%;
}

.button-submit {
  padding: 10px 42px;
  background-color: #151515;
  color: white;
  font-weight: 700;
  font-family: "Mark Pro", sans-serif;
  font-size: 15px;
  letter-spacing: 1px;
  margin-top: 20px;
  cursor: pointer;
}
.button-submit:hover {
  background-color: #2b2e31;
}

.history-intro {
  display: flex;
  flex-direction: column;
}
.history-text-1 {
  position: relative;
  max-width: 530px;
  font-size: 40px;
  line-height: 1.2;
  font-weight: 800;
  color: #ffffff;
}
.history-bottom {
  margin-left: auto;
  max-width: 70%;
  padding-top: 30px;
}

.team-intro {
  position: relative;
  display: flex;
  padding: 0 0 80px 0;
  margin-top: 50px;
}
.team-left {
  padding-left: 8.3333%;
  width: 25%;
}

.team-text-1 {
  position: relative;
  top: 80px;
  left: 0;
  font-size: 6rem;
  line-height: 6.6rem;
  font-weight: 800;
  max-width: 500px;
  color: #ffffff;
  margin-right: -300px;
  z-index: 100;
}
.team-txt-2 {
  position: absolute;
  bottom: 0;
  left: 16.33%;
  max-width: 500px;
}
.team-txt-2 p {
  margin: 0;
}

.gallery {
  padding: 50px 0;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

.team-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -17px;
  margin-right: -17px;
}
.team-list .team-block {
  width: 33.333%;
  padding: 17px;
}
.team-list .team-block .video-img {
  margin-bottom: 20px;
}
.team-list .team-block .video-txt h4 {
  margin-bottom: 12px;
}
.team-center {
  margin: 0 auto;
  width: 54.5455%;
}

.card {
  padding-left: 40px;
  border-left: 1px solid #1e1e1e;
  position: relative;
  padding-bottom: 40px;
  padding-right: 40px;

  &:before {
    position: absolute;
    content: "";
    border-radius: 100%;
    background: #969696;
    width: 0.8em;
    height: 0.8em;
    top: -0.4em;
    left: -0.4em;
    opacity: 1;
  }

  .h4title {
    font-weight: bold;
  }

  .pdesc {
    color: #969696;
  }
}

.mg-block {
  display: flex;
  align-items: center;
}
.mg-block:nth-child(even) {
  flex-direction: row-reverse;
}
.mg-block .video-img {
  width: 50%;
}
.mg-block .video-txt {
  padding: 50px;
  width: 50%;
}

.email_list {
  padding: 50px 0px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}
.email_list p {
  border-left: 2px solid rgba(255, 255, 255, 0.1);
  padding-left: 20px;
}
.email_list p strong {
  display: block;
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 15px;
}
.email_list p a {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.email_list p a:hover {
  color: #ffffff;
}
.email_list p a span {
  margin-right: 7px;
}
.email_list p a svg {
  margin-right: 7px;
}

.carrellata video {
  width: 100%;
  height: auto;
}

.accept p {
  font-size: 13px;
  padding: 20px 0 0 0;
  clear: both;
  margin: 0;
}
.accept label {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.accept label input {
  width: 10px;
  margin-right: 8px;
}
.accept span.wpcf7-list-item {
  margin: 0;
}

.react-slider .swiper-button-next,
.react-slider .swiper-button-prev {
  color: #787878;
  &:hover {
    color: #ffffff;
  }
}

.calendario-dl {
  display: inline-flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
}
.calendario-dl-img {
  margin-right: 40px;
}
.calendario-dl-txt p {
  margin: 0;
}
.calendario-dl-txt h2 {
  margin: 0;
}
.calendario-dl-txt h3 {
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
}

.silusi-legno img,
.silusi-metallo img {
  filter: none;
}

.sede2 {
  padding: 60px 20px 0 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  text-align: center;
  h3 {
    margin-bottom: 10px;
  }
}

.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%;
}
.icon-scroll {
  cursor: pointer;
  width: 40px;
  margin-left: -20px;
  height: 70px;
  bottom: 30px;
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 25px;
}
.icon-scroll:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
}
@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}
@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}
.swiper-pagination-bullet .swiper-pagination-bullet-active,
.swiper-pagination-bullet-active {
  color: #ffffff !important;
  background: #ffffff !important;
}

.loader {
  display: none !important;
}
.demo-gallery img {
  width: 100%;
  height: auto;
  background: #f0f0f0;
}
.demo-gallery ul {
  padding-left: 1rem;
  list-style: none;
}
.demo-gallery li {
  flex-shrink: 0;
  width: clamp(500px, 60vw, 800px);
  padding-right: 1rem;
}
.demo-wrapper {
  overflow-x: hidden;
}
.demo-gallery .wrapper {
  display: flex;
}
.demo-gallery:not(.last) {
  padding-bottom: 1rem;
}

.demo-text {
  width: 100%;
}
.overdiv {
  overflow: hidden;
}
.demo-text .text {
  font-size: clamp(8rem, 15vw, 16rem);
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.421rem;
}

.fancybox__image {
  filter: none;
}

.parallax-banner {
  display: block;
}
.parallax_mobile {
  display: none;
}

@media (min-width: 1080px) and (max-width: 1580px) {
  .video-section h2 {
    font-size: 40px;
    max-width: 60%;
  }
}

@media (min-width: 1080px) and (max-width: 1280px) {
  .container,
  .responsive {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  #menu .main-nav-menu button {
    font-size: 22px;
    line-height: 25px;
    text-align: left;
  }
  .email_list {
    padding: 50px 20px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 1079px) {
  .container,
  .responsive {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  #menu .main-nav-menu button {
    font-size: 22px;
    line-height: 25px;
    text-align: left;
    margin: 30px 0;
  }
  .pl {
    padding-left: 40px;
  }
  .pr {
    padding-right: 40px;
  }
  .section-wrapper {
    min-height: auto;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }
  .video-section {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    justify-content: flex-start;
    display: flex;
    align-items: flex-start;
    position: relative;
    height: auto;
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }
  .section-wrapper:nth-child(odd) .video-section {
    justify-content: flex-end;
    flex-direction: column-reverse;
  }
  .video-section h2 {
    font-weight: 800;
    margin: 0px 0;
    font-size: 40px;
    position: relative;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px 0px;
    transform: none;
    left: auto;
    text-align: left;
  }
  .section-wrapper:nth-child(odd) .video-section h2 {
    left: auto;
  }
  .video-section .video-txt {
    flex: 1;
    padding: 20px;
  }
  .menu-logo {
    padding-bottom: 66px;
    width: 267px;
  }
  .home-caption h1 {
    position: relative;
    font-weight: 300;
    font-size: 74px;
    line-height: 1.1;
  }
  .parallax-banner {
    height: 700px;
    filter: grayscale(100%);
    transition: all 4s ease;
  }
  .parallax-caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 60px 0;
    width: 92%;
  }
  H2 {
    font-size: 33px;
  }
  .email_list {
    padding: 50px 20px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }

  .parallax-banner {
    display: none;
  }
  .parallax_mobile {
    display: block;
  }
  .parallax_mobile_content img {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .container,
  .responsive {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  body {
    font-size: 1.8rem;
    line-height: 3rem;
    letter-spacing: 0.09rem;
  }
  H1 {
    color: #fff;
    font-size: 4.2rem;
    line-height: 4.2rem;
  }
  h2 {
    font-size: 3.6rem;
    line-height: 4.8rem;
  }
  h2 {
    font-size: 3.6rem;
    line-height: 4.8rem;
  }
  h3 {
    font-size: 3rem;
    line-height: 4rem;
  }
  h4 {
    font-size: 2.6rem;
    line-height: 3.8rem;
  }
  h5 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .sup {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
  .team-center {
    margin: 0 auto;
    width: 100%;
  }
  .team-left {
    padding-left: 0;
    width: 100%;
  }

  .odd_even .pb {
    padding-bottom: 50px;
  }
  .odd_even .pt {
    padding-top: 50px;
  }
  .odd_even .fluid_content .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .odd_even .fluid_content:nth-child(odd) .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .odd_even .fluid_content .row .text_col {
    width: 100%;
    padding: 15px 0;
  }
  .odd_even .fluid_content .row .children_col {
    width: 100%;
    padding: 15px 0;
  }
  .odd_even .fluid_content:nth-child(odd) .row .text_col {
    padding-left: 0px;
  }
  .odd_even .fluid_content:nth-child(even) .row .text_col {
    padding-right: 0px;
  }

  .mg-block:nth-child(even) {
    flex-direction: column;
  }
  .mg-block {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .mg-block .video-img {
    width: 100%;
  }
  .mg-block .video-txt {
    padding: 50px;
    width: 100%;
  }
  .team-list .team-block {
    width: 50%;
    padding: 17px;
  }
  .team-txt-2 {
    position: relative;
    bottom: auto;
    left: auto;
    max-width: 500px;
  }
  .team-intro {
    flex-direction: column;
  }
  .menu-logo {
    padding-bottom: 110px;
    width: 150px;
    margin: 0 auto;
  }
  .email_list {
    padding: 50px 20px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
  .parallax-banner {
    display: none;
  }
  .parallax_mobile {
    display: block;
  }
  .parallax_mobile_content img {
    margin-bottom: 30px;
  }
  #menu button.toggle-menu {
    z-index: 3;
  }
}
@media (max-width: 520px) {
  .container,
  .responsive {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .row {
    display: flex;
    flex-direction: column;
  }
  .row > div {
    width: 100%;
  }
  .swiper-cards {
    width: 230px;
    height: 220px;
    margin: 0 auto;
  }
  .stats {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
  }
  .stats > div {
    text-align: center;
    width: 100%;
    padding: 0 20px;
  }
  .stats > div {
    text-align: center;
    width: 100%;
    padding: 0 20px;
  }
  .stats .odometer {
    font-size: 43px;
    line-height: 50px;
    text-align: center;
    font-weight: 800;
  }
  .testata {
    padding: 150px 0 40px;
  }
  .parallax-quality {
    position: relative;
  }
  .parallax-quality ul {
    display: flex;
    color: #969696;
    display: flex;
    grid-template-columns: auto auto auto;
    flex-direction: column;
  }
  .footer-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .footer-row .footer-right {
    width: 100px;
    margin-bottom: 30px;
    order: 1;
  }
  .footer-row .footer-left {
    order: 2;
  }

  .home-caption h1 {
    position: relative;
    font-weight: 300;
    font-size: 50px;
    line-height: 1.1;
  }
  .nav__content ul {
    display: flex !important ;
    flex-direction: column !important;
  }
  .section-mercati .row {
    display: flex;
    flex-direction: column;
  }
  .section-mercati .row > div {
    display: flex;
    flex-direction: column;
  }
  .section-mercati:nth-child(even) .row {
    display: flex;
    flex-direction: column;
  }
  .pr {
    padding-right: 0;
    padding-bottom: 12px;
  }
  .pl {
    padding-left: 0px;
    padding-top: 12px;
  }
  .parallax-caption {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    padding: 60px 20px;
  }
  .parallax-overlay {
    background-image: linear-gradient(
      rgba(3, 3, 3, 0.4) 0%,
      rgba(3, 3, 3, 0.5) 100%
    );
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    height: auto;
  }
  .team-list .team-block .video-txt h4 {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 22px;
  }
  .team-text-1 {
    position: relative;
    top: 80px;
    left: 0;
    font-size: 4rem;
    line-height: 6.6rem;
    font-weight: 800;
    max-width: 500px;
    color: #ffffff;
    margin-right: auto;
    z-index: 100;
  }
  .video-section .video-img {
    width: 100%;
    padding: 12px;
  }
  .video-section .video-txt {
    width: 100%;
    padding: 12px;
  }
  .row {
    width: 100%;
  }
  .video-section {
    padding: 15px 0px;
    flex-direction: column-reverse;
    height: auto;
  }
  .section-mercati {
    padding: 40px 0;
  }
  .section-mercati .section-txt {
    width: 100%;
  }
  .section-mercati .section-img {
    width: 100%;
    position: relative;
  }
  .section-mercati .row {
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }
  .section-wrapper .video-section {
    justify-content: flex-start;
    flex-direction: column;
  }
  .section-wrapper:nth-child(odd) .video-section {
    justify-content: flex-start;
    flex-direction: column;
  }
  .section-wrapper {
    min-height: auto;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0;
  }
  .video-section h2 {
    font-weight: 800;
    margin: 0px 0;
    font-size: 28px;
    line-height: 32px;
    position: relative;
    top: auto;
    background-color: transparent;
    padding: 0 12px 20px 12px;
    transform: none;
    left: auto;
    right: auto;
    width: 100%;
  }
  .section-wrapper:nth-child(odd) .video-section h2 {
    left: auto;
    right: auto;
  }
  .parallax-banner {
    height: 900px;
    filter: grayscale(100%);
    transition: all 4s ease;
  }
  .stats-sedi {
    flex-wrap: wrap;
  }
  .stats-sedi > div {
    width: 100%;
    padding: 12px;
    border-right: none;
  }
  .carrellata {
    padding: 0;
    filter: grayscale(100%);
  }
  .accordion-title {
    font-size: 21px;
    font-family: "Mark Pro", sans-serif;
    letter-spacing: 1px;
    margin: 0;
    text-align: left;
  }
  .team-text-1 {
    font-size: 46px;
    line-height: 48px;
  }
  .email_list {
    grid-gap: 12px;
    grid-template-columns: 1fr;
  }
  .contatti-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .contatti-wrapper .contatti_italia {
    width: 100%;
  }
  .contatti-wrapper .contatti_romania {
    width: 100%;
  }
  .contatti-wrapper ul li {
    background: rgba(27, 27, 27, 0.19);
    margin: 12px 0;
    padding: 12px 0;
    border-left: none;
  }
}
