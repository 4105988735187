.gallery-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}
.gallery-inner-img {
  position: relative;
  a {
    display: block;
    position: relative;
    background-position: center;
    background-size: cover;
    filter: grayscale(1);
    &::before {
      content: " ";
      display: block;
      padding-top: 66%;
    }
    img {
      position: absolute;
      object-fit: contain;
      top: 0;
      left: 0;
    }
    &:hover {
      filter: grayscale(0);
    }
  }
}

.people .gallery-grid a {
  display: block;
  position: relative;
}

.largeContent {
  overflow: hidden;
  height: 100vh;
}
#section4 .clipFirst,
.clipSecond {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.clipFirst img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(0.8);
}

.clipSecond {
  clip-path: inset(0);
  z-index: 2;
}

.clipSecond img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: saturate(1.5);
}

#gallery_weird {
  background-color: black;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  height: 100vh;
}

#gallery_weird .slider {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

#gallery_weird .slider .sliderImg {
  height: 100%;
  width: 33%;
  object-fit: cover;
}

.galleryh {
  display: flex;
  min-height: 100vh;
}

.galleryh .dir {
  position: fixed;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -200%);
}

.galleryh .txtBlock {
  flex-basis: 33%;
  padding: 60px;
}

.galleryh .thumbs {
  flex-basis: 66%;
  font-size: 0;
  line-height: 0;
}

.galleryh .thumb {
  display: inline-block;
  width: 46%;
  margin-top: 2%;
  margin-left: 2%;
}

.galleryh .thumbImg {
  background: #fff;
  position: relative;
  display: block;
  width: 100%;
  height: 290px;
}

.carrellata {
  padding: 75px;
  filter: grayscale(100%);
  &:hover {
    filter: grayscale(0);
  }
}
.carrellata .col-6 {
  padding: 0 5px;
}

.video-link {
  position: relative;
  display: block;
  .video-icon-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #a5a5a5;
    font-size: 70px;
    display: flex;
    align-items: center;
  }

  &:hover .video-icon-play {
    color: white;
  }
}
