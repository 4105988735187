$anim-text-offset: 1em;
.button {
  touch-callout: none;
  user-select: none;
  display: inline-block;
  border: 0.1em solid;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  opacity: 0.6;
  color: #fff;
  &__text {
    display: block;
    padding: 0.7em 3em;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 15px;
    &:before {
      content: attr(title);
    }
    &--bis {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translateX(-1 * $anim-text-offset);
      opacity: 0;
    }
  }
  &__mask {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    transform: translateX(-100%) rotate(45deg);
    transition: all 0.3s;
  }
}

.button:hover {
  opacity: 1;
  .button__text {
    animation: fx-text 0.3s ease-out;
    &--bis {
      animation: fx-text-bis 0.3s ease-out;
    }
  }
  .button__mask {
    animation: fx-mask 0.3s ease-out;
  }
}

.button:active {
  opacity: 1;
  background: white;
  color: inherit;
}

@keyframes fx-mask {
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) rotate(45deg);
  }
}

@keyframes fx-text {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX($anim-text-offset);
    opacity: 0;
  }
}
@keyframes fx-text-bis {
  0% {
    transform: translateX(-1 * $anim-text-offset);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
