$menu_button_color: #ffffff;

#menu {
  background: #1e1e1e;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  text-align: left;
  top: 0;
  transition: all 0.7s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 1100;
  height: 100vh;
}
#menu button.toggle-menu {
  position: absolute;
  top: 30px;
  right: 30px;
}
.nav-wrapper {
  display: flex;
  justify-content: space-between;
}
.social_header {
  font-size: 30px;
}
#menu .menu-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#menu.open {
  opacity: 1;
  visibility: visible;
}
#menu.open li:nth-child(1) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
#menu.open li:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
#menu.open li:nth-child(3) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
#menu.open li:nth-child(4) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
#menu.open li:nth-child(5) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
#menu.open li:nth-child(6) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
#menu.open li:nth-child(7) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
#menu.open li:nth-child(8) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
#menu.open li:nth-child(9) {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
#menu.open li:nth-child(10) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
#menu.open li {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
#menu ul:hover button {
  opacity: 0.5;
}
#menu ul button {
  -webkit-transform: translateZ(0);
  transition: opacity 0.3s ease-in-out;
}
#menu ul button:hover {
  opacity: 1;
}
#menu .main-nav {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
#menu .main-nav-menu ul {
  list-style: none;
  margin: 0;
  padding: 0 30px;
  width: 33.33%;
  border-left: 1px solid rgb(81 81 81 / 50%);
}
#menu .main-nav-menu button {
  background: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
  display: block;
  font-size: 32px;
  line-height: 1.3;
  margin: 20px 0;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-decoration: none;
  font-family: "Mark Pro", sans-serif;
  text-align: left;
}
#menu .main-nav-menu a:first {
  padding-top: 0;
}
#menu .menu-footer ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0 20px;
}
#menu .menu-footer ul li {
  display: inline;
  margin: 0 1rem;
}
#menu .menu-footer button {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
}
.menu-logo {
  padding-bottom: 110px;
  width: 350px;
}
.container-menu {
  width: 90%;
  padding: 0 50px;
  margin: 0 auto;
}
.main-nav-menu {
  display: flex;
  justify-content: center;
  width: 100%;
}
.main-nav-menu ul {
  padding: 0 20px;
}

.toggle-menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  height: 50px;
  outline: none;
  padding: 0;
  pointer-events: initial;
  position: relative;
  vertical-align: middle;
  width: 50px;
}
.toggle-menu span {
  background-color: $menu_button_color;
  content: "";
  display: block;
  height: 2px;
  left: calc(50% - 13px);
  position: absolute;
  top: calc(50% - 1px);
  transform-origin: 50% 50%;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
    transform 0.2s linear;
  width: 26px;
}
.toggle-menu span:before,
.toggle-menu span:after {
  background-color: $menu_button_color;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  transform-origin: 50% 50%;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
    transform 0.2s linear;
  width: 26px;
}
.toggle-menu span:before {
  top: 7px;
}
.toggle-menu span:after {
  top: -7px;
}
.toggle-menu.active span {
  background-color: transparent;
  transition: background 0.2s ease-out;
}
.toggle-menu.active span:before,
.toggle-menu.active span:after {
  transition: top 0.2s ease-out, transform 0.2s 0.2s ease-out;
}
.toggle-menu.active span:before {
  top: 0;
  transform: rotate3d(0, 0, 1, -45deg);
}
.toggle-menu.active span:after {
  top: 0;
  transform: rotate3d(0, 0, 1, 45deg);
}

@media (max-width: 767px) {
  #menu {
    .container-menu {
      margin: 0 auto;
      padding: 0 50px;
      width: 100%;
      .main-nav-menu {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-direction: column;
        ul {
          width: 100%;
          padding: 0;
          border-left: none;
          li {
            button {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
