.accordion-section {
  display: flex;
  flex-direction: column;
  padding: 0 0px;
}

.accordion-section .accordion {
  color: #ffffff;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  background-color: black;
  border: none;
  border-bottom: 1px solid rgba(181, 181, 181, 0.27);
  &:hover {
    border: none;
    border-bottom: 1px solid white;
  }
}

.accordion-section .active {
  background-color: #000000;
  border-bottom: 1px solid #000000;
}

.accordion-title {
  font-size: 34px;
  font-family: "Mark Pro", sans-serif;
  letter-spacing: 2px;
  margin: 0;
}

.accordion-icon {
  margin-left: auto;
  transition: transform 0.1s ease;
  width: 17px;
}

.accordion-section .rotate {
  transform: rotate(180deg);
}

.accordion-content {
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-text {
}
